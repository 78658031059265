<template>
  <div class="user">
    <all-log :dialogLog="dialogLog" />
    <!-- 修改用户 -->
    <el-dialog
      :visible.sync="dialogFormVisible"
      @close="userClose(form)"
      :append-to-body="true"
    >
      <div slot="title" class="dialog-footer">
        <div class="title" v-show="openState">添加付款方式</div>
        <div class="title" v-show="!openState">修改付款方式</div>
      </div>
      <el-form :model="form">
        <el-form-item label="付款方式名称" :label-width="formLabelWidth">
          <el-input v-model="form.name" placeholder="付款方式名称"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="setAdd" v-show="openState"
          >添 加</el-button
        >
        <el-button type="primary" @click="setEdit" v-show="!openState"
          >修 改</el-button
        >
      </div>
    </el-dialog>
    <!-- 删除用户提示 -->
    <el-dialog :visible.sync="dialogVisible" width="30%" :append-to-body="true">
      <div slot="title" class="dialog-footer">
        <div class="title">删除付款方式</div>
      </div>
      <div style="margin-bottom: 10px">{{ userdelinfo.name }}</div>
      <el-cascader
        placeholder="关联任务"
        :options="optionsTask"
        :props="optionPropsTask"
        v-model="delForm.task_id"
        filterable
      ></el-cascader>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="setDel">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 面包屑导航 -->
    <el-card>
      <div class="menu-box">
        <h2>付款方式管理</h2>
        <div class="box-bd">
          <!-- 头 -->
          <el-row :gutter="20">
            <el-col :span="5">
              <el-input placeholder="请输入内容" v-model="getInfo.info">
                <el-button
                  slot="append"
                  icon="el-icon-search"
                  @click="catalogue"
                ></el-button>
              </el-input>
            </el-col>
            <el-col :span="8">
              <el-button type="primary" @click="addOpen()"
                >添加付款方式</el-button
              >
              <el-button type="warning" @click="dialogOpen">查看日志</el-button>
            </el-col>
          </el-row>
          <!-- 表格 -->
          <el-table
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
          >
            <el-table-column prop="name" label="付款方式名称"></el-table-column>
            <el-table-column label="状态" width="80">
              <template slot-scope="scope">
                <el-switch
                  @change="changeState(scope.row)"
                  v-model="scope.row.state"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  :active-value="1"
                  :inactive-value="2"
                >
                </el-switch>
              </template>
            </el-table-column>
            <el-table-column width="180" label="操作">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="warning"
                  icon="el-icon-edit"
                  @click="editOpen(scope.row)"
                  >编辑</el-button
                >
                <el-button
                  type="danger"
                  size="mini"
                  icon="el-icon-delete"
                  @click="delOpen(scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import {
  getList,
  getEdit,
  getAdd,
  getDel,
  getState,
} from '@/api/accounting/financePay.js'

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      optionPropsTask: {
        value: 'id',
        label: 'name',
        children: 'son',
        disabled: 'state',
      },
      optionsTask: [],
      delForm: {
        task_id: '',
      },
      dialogLog: {
        state: false,
      },
      spacer: '\u00a0\u00a0\u00a0',
      openState: true,
      //配置
      optionProps: {
        checkStrictly: true,
        value: 'id',
        label: 'name',
        children: 'son',
      },
      //总页数
      exp: {
        count: Number,
        num: 0,
      },
      //当前页码
      getInfo: {
        //搜索框
        info: '',
        page_code: 1,
        page_num: 7,
      },
      //导航
      nav: [],
      //单选列表
      radiolist: [
        {
          id: 1,
          name: '一级',
        },
        {
          id: 2,
          name: '二级',
        },
      ],
      getNav: [],
      //即将删除的信息
      userdelinfo: {},
      // 添加信息表单
      form: {
        name: '',
      },
      //表单名称长度
      formLabelWidth: '120px',
      //控制弹窗删除
      dialogVisible: false,
      //控制弹窗--新增修改
      dialogFormVisible: false,
      //搜索框
      inputSearch: '',
      //表格数据
      tableData: [],
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    dialogOpen() {
      this.dialogLog.state = true
    },
    async changeState(data) {
      let info = await getState({ id: data.id })
      this.judge(info)
    },

    //修改每页数量
    sizeChange(value) {
      console.log(value)
      this.getInfo.page_num = value
      this.catalogue()
    },
    //分页切换
    pageChange(value) {
      this.getInfo.page_code = value
      this.catalogue()
    },
    //打开添加
    addOpen() {
      this.openState = true
      this.dialogFormVisible = true
    },
    //打开编辑
    editOpen(row) {
      this.openState = false
      this.dialogFormVisible = true
      row = JSON.stringify(row)
      this.form = JSON.parse(row)
      console.log(this.form)
    },
    //编辑打开
    async setEdit() {
      let info = await getEdit(this.form)
      this.judge(info)
    },
    //删除打开
    delOpen(row, form) {
      this.dialogVisible = true
      this.userdelinfo = row
    },
    // 删除
    async setDel() {
      let info = await getDel({
        id: this.userdelinfo.id,
        task_id: this.delForm.task_id,
      })
      this.judge(info)
    },
    //搜索事件
    search(e) {
      console.log('搜索信息: ' + e)
    },
    //添加事件--提交事件
    async setAdd(e) {
      let info = await getAdd(this.form)
      this.judge(info)
    },
    judge(info) {
      if (info.data.code == 0) {
        this.$message({
          message: info.data.message,
          type: 'success',
        })
        this.dialogFormVisible = false
        this.dialogVisible = false
        this.catalogue()
      } else {
        this.$message({
          showClose: true,
          message: info.data.message,
          type: 'error',
        })
        return
      }
    },
    //添加事件 退出弹窗
    userClose(e) {
      this.form = {
        name: '',
      }
      console.log('close')
    },
    //目录接口
    async catalogue() {
      let info = await getList(this.getInfo)
      console.log(info.data.data)
      this.tableData = info.data.data
      let optionsTask = await this.$getOptionTask()
      this.optionsTask = optionsTask.data.data
    },
  },
  beforeCreate() {}, //生命周期 - 创建之前
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  beforeMount() {}, //生命周期 - 挂载之前
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.catalogue()
  },
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
}
</script>

<style scoped>
.page {
  margin-top: 20px;
}
.title {
  font-size: 18px;
  font-weight: bold;
}
.set-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.set-page-left {
  color: #0000006d;
  font-size: 14px;
  font-weight: 400;
}
</style>
