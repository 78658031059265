import { post } from '@/utils/request'

//获取目录
export function getList(data) {
    return post('finance/pay/listPay', data)
}
//修改
export function getEdit(data) {
    return post('finance/pay/editPay', data)
}
//添加 
export function getAdd(data) {
    return post('finance/pay/addPay', data)
}
//删除
export function getDel(data) {
    return post('finance/pay/delPay', data)
}
//分类下拉
export function getOption(data) {
    return post('finance/pay/selectPay', data)
}
//状态修改
export function getState(data) {
    return post('finance/pay/statePay', data)
}


